import React, { useState, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { MdEdit } from 'react-icons/md'
import { Input, Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import numeral from 'numeral'
import StatsCard from './StatsCard'

// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';


// import { Bar as ChartBar } from 'react-chartjs-2';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

const ChartRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5rem;
`

const BarBackground = styled.div`
  background-color: ${props => props.theme.dashboard.metricTitle || '#ADADAD'};
  height: 1.5rem;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
`

const BarFill = styled.div`
  background-color: ${props => props.theme.dashboard.chartAccent || '#22C55E'};
  width: ${({ width }) => width};
  height: 100%;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.3s ease-in-out;
`

const BarLabel = styled.p`
  color: ${props => props.theme.dashboard.cardBackground || '#191919'};
  font-size: 0.875rem;
  text-align: center;
  padding: 0 0.5rem;
  white-space: nowrap;
`

const RangeRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.25rem;
`

const RangeLabel = styled.span`
  font-size: 0.875rem;
  font-weight: 700;
  color: ${props => props.theme.dashboard.metricTitle || '#ADADAD'};
  display: inline-flex;
  align-items: center;
`

export const SalesTarget = ({ monthlyContracts, salesTarget, updateSalesTarget, employeeRole, tooltipText }) => {
  const theme = useContext(ThemeContext);
  const [popoverOpen, setPopoverOpen] = useState(false)

  const monthlyTotal = monthlyContracts?.reduce((acc, contract) => {
    return acc + contract.revenueData?.yearlyTotal
  }, 0) || 0 // Ensure monthlyTotal is always a number

  const currentTarget = salesTarget?.target || 0 // Ensure target is a number

  const percentCompletedValue = currentTarget > 0 ? Math.min(100, Math.round((monthlyTotal / currentTarget) * 100)) : 0;
  const percentCompleted = `${percentCompletedValue}%`


  const handleTargetChange = (e) => {
    const newTarget = parseInt(e.target.value)
    const targetReached = monthlyTotal >= newTarget
    updateSalesTarget({
      met: targetReached,
      target: newTarget
    })
    setPopoverOpen(false)
  }

  const showInputToggle = employeeRole === 'administrator' || employeeRole === 'owner'

  // const maxChart = salesTarget.target
  // const minChart = 0

  // Function to determine bar color based on completion percentage
  // const getBarBackgroundColor = () => {
  //   if (!theme) return '#ccc'; // Default color if theme is not available

  //   const colors = {
  //     error: theme.colors.error || '#dc3545',
  //     primary: theme.colors.primary || '#0d6efd',
  //     success: theme.colors.success || '#198754'
  //   }

  //   if (percentCompletedValue < 50) {
  //     return colors.error;
  //   } if (percentCompletedValue < 100) {
  //     return colors.primary;
  //   } else {
  //     return colors.success;
  //   }
  // };

  // const optionsChart = {
  //   indexAxis: 'y',
  //   maintainAspectRatio: false,
  //   plugins: {
  //     legend: {
  //       display: false,
  //       labels: {
  //         color: theme.colors.white
  //       }
  //     },
  //     tooltip: {
  //       callbacks: {
  //         label: function (context) {
  //           return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.x);
  //         }
  //       }
  //     }
  //   },
  //   scales: {
  //     x: {
  //       ticks: {
  //         display: true,
  //         color: theme.colors.white,
  //         callback: (value, index, values) => {
  //           let label = index === 0 || index === values.length - 1 ? value : "";

  //           const currencyFormat = {
  //             notation: "compact",
  //             compactDisplay: "short",
  //             style: 'currency',
  //             currency: 'USD'
  //           }

  //           if (label !== "" && label != 0) {
  //             return new Intl.NumberFormat('en-US', currencyFormat).format(label);
  //           } else {
  //             return label
  //           }
  //         },
  //       },
  //       grid: {
  //         display: false,
  //         drawTicks: false,
  //         drawBorder: false,
  //         drawOnChartArea: false
  //       },
  //       min: minChart,
  //       max: maxChart
  //     },

  //     y: {
  //       ticks: {
  //         display: false,
  //         color: theme.colors.white,
  //       },
  //       grid: {
  //         display: false,
  //         drawBorder: false,
  //         drawTicks: false,
  //         drawOnChartArea: false
  //       },
  //     }
  //   },
  // };

  // const dataChart = {
  //   labels: ['Sales'],
  //   datasets: [{
  //     axis: 'y',
  //     data: [monthlyTotal],
  //     backgroundColor: getBarBackgroundColor,
  //     barThickness: 25
  //   }],
  // }

  return (
    <StatsCard title={'Monthly Sales Target'} tooltipText={tooltipText}>
      <ChartRow>
        <BarBackground>
          <BarFill width={percentCompleted}>
            {percentCompletedValue > 10 && <BarLabel>{percentCompleted}</BarLabel>}
          </BarFill>
        </BarBackground>
      </ChartRow>
      <RangeRow>
        <RangeLabel>$0</RangeLabel>
        <RangeLabel>
          {numeral(currentTarget).format('$0,0')}
          {showInputToggle ? (
            <>
              <MdEdit
                id="editSalesTargetIcon"
                size={'1.2em'}
                color={theme.dashboard.metricTitle || '#ADADAD'}
                style={{ cursor: 'pointer', marginLeft: '0.3em' }}
                onClick={() => setPopoverOpen(!popoverOpen)}
              />
              <Popover
                placement="top"
                isOpen={popoverOpen}
                target="editSalesTargetIcon"
                toggle={() => setPopoverOpen(!popoverOpen)}
              >
                <PopoverHeader>Set Monthly Target</PopoverHeader>
                <PopoverBody>
                  <Input
                    style={{
                      fontSize: '0.875rem'
                    }}
                    type="number"
                    name="salesTarget"
                    id="salesTargetInput"
                    placeholder="Enter Sales Target"
                    defaultValue={salesTarget?.target}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleTargetChange(e)
                      }
                    }}
                    autoFocus
                  />
                </PopoverBody>
              </Popover>
            </>
          ) : null}
        </RangeLabel>
      </RangeRow>
    </StatsCard>
  )
}

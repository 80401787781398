import React, { useState, memo, useContext, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Cell, PieChart, Pie, ResponsiveContainer, Tooltip } from 'recharts';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import numeral from 'numeral';
import _ from 'lodash'

import StatsCard from './StatsCard'
import { capitalize } from 'utils'
import MotivationalMessage from './MotivationalMessage'
import { PieLabelArray } from './PieLabelArray'
import { renderCustomPieChartLabel, renderCustomPieChartLabelLine, removeSmallData } from '../util'

const DropdownContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const containerStyles = `
  grid-row-start: 3;
  grid-row-end: span 2;
  grid-column-start: 3;

  @media (max-width: 1250px) {
    grid-row-start: 5;
    grid-row-end: span 2;
    grid-column-start: 1;
  }

  @media (max-width: 700px) {
    grid-row-start: 7;
    grid-row-end: span 2;
    grid-column-start: 1;
  }
`

const formatTooltipLabel = (value, name, props) => [numeral(value).format('$0,0[.]00'), name]

export const SalesByLocationType = memo(({ stats, tooltipText }) => {
  const theme = useContext(ThemeContext);
  const bluePalette = theme.dashboard.chartSalesGradient || [];

  const sortedData = useMemo(() => {
    let data = [];
    if (stats) {
      data = Object.keys(stats).map(key => {
        const value = stats[key] || 0
        return {name: key, value}
      })
      data.sort((a, b) => b.value - a.value);
    }
    return data;
  }, [stats]);

  return (
    <StatsCard
      title={'Sales by Location Type'}
      containerStyles={containerStyles}
      tooltipText={tooltipText}
    >
      {sortedData?.length ? (
        <>
          <div style={{ width: '100%' }}>
            <ResponsiveContainer height={200} width="100%">
              <PieChart key={Math.random()}>
                <Pie
                  dataKey="value"
                  data={sortedData}
                  cx="50%"
                  cy="50%"
                  innerRadius={5}
                  outerRadius={50}
                  label={(props) => renderCustomPieChartLabel({...props, theme})}
                  labelLine={(props) => renderCustomPieChartLabelLine({...props, theme})}
                  paddingAngle={0}
                  startAngle={90}
                  endAngle={-270}
                >
                  {sortedData.map((entry, index) => {
                    const colorIndex = index % bluePalette.length;
                    const fillColor = bluePalette[colorIndex];
                    return (
                      <Cell key={`cell-${index}`} fill={fillColor} stroke={fillColor} strokeWidth={1}/>
                    )
                  })}
                </Pie>
                <Tooltip formatter={formatTooltipLabel} />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <PieLabelArray data={sortedData} shortenTo={4} elementId={'sales-by-location-type-labels'} colorPalette={bluePalette} />
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <MotivationalMessage message={`Cue "Sounds of Silence"`} />
        </div>
      )}
    </StatsCard>
  )
}, (prevProps, nextProps) => {
  return _.isEqual(prevProps.stats, nextProps.stats) && prevProps.tooltipText === nextProps.tooltipText;
})
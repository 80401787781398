import React from 'react';
import { ThemeContext } from 'styled-components';

export const renderCustomPieChartLabel = ({ cx, cy, x, y, midAngle, innerRadius, outerRadius, percent, value, index, theme, ...props }) => {
  if (percent < 0.02) return null

  const fillColor = theme?.dashboard?.metric || 'white';

  return (
    <text x={x} y={y} fontSize={14} fill={fillColor} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${parseInt(percent * 100)}%`}
    </text>
  );
};

export const renderCustomPieChartLabelLine = ({points, percent, theme}) => {
  let path = ''
  if (percent < 0.02) return null
  points.forEach((point, index) => {
    path += `${point.x},${point.y} `
  })

  const strokeColor = theme?.dashboard?.metricTitle || 'white';

  return (
    <polyline points={path} stroke={strokeColor} fill="none" />
  );
}

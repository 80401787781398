import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'
import { bindActionCreators } from 'redux'
import { Container, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap'
import styled, { withTheme } from 'styled-components'
import _ from 'lodash'
import * as api from 'api'
import TaskTemplates from 'data/TaskTemplates.json'
import { toast } from 'react-toastify'
import networkErrorToast from 'utils/networkErrorToast'
import { connectConfirmation } from 'components/confirm'
import { amplitudeInstance } from '../../../../store/middleware/analytics'
import { downloadProposalPdf, handleProposalDownload } from 'utils/proposalPdfUtils'

import ActionBar from 'components/layout/ActionBar'
import ProposalForm from './ProposalForm'
import ProposalPreview from './ProposalPreview'
import * as md from './ProposalToMarkdown'
import FileDownload from '../components/FileDownload'
import EmailForm from '../../components/forms/EmailForm'
import CloneForm from '../../components/forms/CloneForm'
import TitleDisplay from '../components/TitleDisplay'
import { axiosClient } from 'store'
import { ActionButtons } from 'components/lib/Button'
import Icon from 'components/icons'

const ScrollableColumn = styled(Col)`
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
`

const Tab = styled.span`
  display: block;
  float: left;
  padding: 6px 22px 6px;
  background-color: ${props => props.theme.colors.clarity};
  margin-left: 46px;
  position: relative;
  color: #fff;
  border-radius: 8px 8px 0 0;

  &:before,
  &:after {
    display: block;
    content: ' ';
    position: absolute;
    top: 0;
    height: 100%;
    width: 44px;
    background-color: ${props => props.theme.colors.clarity};
  }
  &:before {
    right: -24px;
    transform: skew(30deg, 0deg);
    box-shadow: rgba(0, 0, 0, 0.1) 3px 2px 5px,
      inset rgba(255, 255, 255, 0.09) -1px 0;
    border-radius: 0 8px 0 0;
  }
  &:after {
    left: -24px;
    transform: skew(-30deg, 0deg);
    box-shadow: rgba(0, 0, 0, 0.1) -3px 2px 5px,
      inset rgba(255, 255, 255, 0.09) 1px 0;
    border-radius: 8px 0 0 0;
  }
`

const AutoSaveIndicator = styled.span`
  color: ${props => props.theme.colors.grey};
  margin-right: 18px;
`

const getBindableTasks = areas => {
  const areaTypes = _.uniq(areas.map(area => area.type))
  const areaGroups = _.groupBy(areas, 'type')
  let results = []

  areaTypes.forEach(areaType => {
    if (TaskTemplates[areaType]) {
      const data = TaskTemplates[areaType]
      let name = data.sectionName
      if (areaGroups[areaType] && areaGroups[areaType].length === 1) {
        name = areaGroups[areaType][0].name
      }

      results.push({
        name,
        tasks: data.tasks
      })
    }
  })

  return results
}

const areasToTasks = areas => {
  return getBindableTasks(areas)
}

class Proposal extends Component {
  state = {
    downloadButtonBusy: false,
    code: null,
    autosaving: false,
    autosaved: false,
    showModal: false,
    showCloneModal: false
  }

  async componentDidMount() {
    const { match, history, getJob, getProposal } = this.props;
    let initialStateUpdates = {};

    if (match?.params?.jobId) {
      const jobId = match.params.jobId;
      try {
        const result = await getJob(jobId);
        if (result?.payload?.data) {
          initialStateUpdates = {
            ...initialStateUpdates,
            job: result.payload.data,
            client: result.payload.data.client,
            location: result.payload.data.location,
            proposal: history?.location?.state?.proposal,
            code: history?.location?.state?.proposal?.code
          };
        } else {
          console.error("Failed to fetch job or job not found");
        }
      } catch (error) {
        console.error("Error fetching job:", error);
        networkErrorToast();
      }
    } else if (match?.params?.proposalId) {
       const proposalId = match.params.proposalId;
       try {
         const result = await getProposal(proposalId);
         if (result?.payload?.data) {
           initialStateUpdates = {
             ...initialStateUpdates,
             proposal: result.payload.data,
             client: result.payload.data.client,
             location: result.payload.data.location,
             code: result.payload.data.code,
             job: result.payload.data.job
           };
         } else {
           console.error("Failed to fetch proposal or proposal not found");
         }
       } catch (error) {
         console.error("Error fetching proposal:", error);
         networkErrorToast();
       }
    }

    if (history?.location?.state?.estimateResults) {
       initialStateUpdates.estimateResults = history.location.state.estimateResults;
    }

    if (Object.keys(initialStateUpdates).length > 0) {
        this.setState(initialStateUpdates);
    }
  }

  componentWillUnmount() {
    if (this.eraseSaved) {
      clearTimeout(this.eraseSaved)
    }
  }

  _locationToTasks = async locationType => {
    await this.props.getTemplates()
    const filteredTemplate = this.props.templates.find(
      d => d.name === locationType
    )
    let results = []
    if (filteredTemplate) {
      const areas = filteredTemplate.areas
      results = getBindableTasks(areas)
    }
    return results
  }

  /**
   * Downloads the proposal as a PDF from preview content
   * Shows appropriate toast notifications for success or failure
   * @param {string} preview - The markdown content to be converted to PDF
   */
  _downloadProposal = async preview => {
    const { values } = this.props;
    const { proposal } = this.state;

    // Set loading state
    this.setState({
      downloadButtonBusy: true
    });

    // Validate required content exists
    if (!preview) {
      toast.error('No content to generate PDF. Please add some content first.');
      this.setState({ downloadButtonBusy: false });
      return;
    }

    try {
      // Create a temporary proposal object with the preview content
      const previewProposal = {
        ...proposal,
        data: {
          elements: [{ type: 'rich_text', data: { markdown: preview } }],
          options: { showPageNumbers: values?.showPageNumbers || false }
        },
        merchant: { name: this.props.currentCompany ? this.props.currentCompany.name : '' },
        client: { name: this.state.client ? this.state.client.name : '' }
      };

      // Use centralized download utility with proper error handling
      await handleProposalDownload(
        previewProposal,
        {
          setIsLoading: (isLoading) => {
            if (isLoading === false) {
              this.setState({ downloadButtonBusy: false });
            }
          },
          onSuccess: () => {
            // Track the download event in analytics
            if (amplitudeInstance && proposal?._id) {
              amplitudeInstance.logEvent('Download Proposal', { id: proposal._id });
            }
          },
          onError: (error) => {
            console.error('PDF download error:', error);
            networkErrorToast({ error });
            this.setState({ downloadButtonBusy: false });
          }
        },
        {
          applyAutoFill: false
        }
      );
    } catch (error) {
      // Final safety fallback (shouldn't normally happen)
      console.error('Unhandled PDF download error:', error);
      networkErrorToast({ error });
      toast.error('Unexpected error. Please try again.');
      this.setState({
        downloadButtonBusy: false
      });
    }
  };

  _emailProposal = async (values, event) => {
    this.setState({
      emailButtonBusy: true
    })

    const formData = new FormData()
    const { client, proposal } = this.state
    let emailList = []

    if (values.emailContact) {
      const { client } = this.state
      emailList.push(client.contact.email)
    }

    if (values.otherEmails) {
      emailList = emailList.concat(values.otherEmails.split(','))
    }

    emailList = emailList
      .map(email => (email = email.trim()))
      .filter(email => email.length > 0)
    values.emailList = emailList
    values.clientId = client._id
    values.proposalId = proposal._id
    values.showPageNumbers = proposal.formData.showPageNumbers

    for (let key in values) {
      formData.append(key, values[key])
    }

    // remove the buggy attachment key
    formData.delete('attachments')

    // if there are attachments, then insert in FormData correctly
    if (values.attachments) {
      values.attachments.forEach(file => {
        formData.append('attachments', file)
      })
    }

    await axiosClient.post('api/proposals/emailpdf', formData, {
      timeout: 30000
    })

    amplitudeInstance &&
      amplitudeInstance.logEvent('Send Proposal', { id: proposal._id })

    this.setState({
      emailButtonBusy: false,
      showModal: false
    })
  }

  _submitProposal = values => {
    if (this.state.proposal) {
      this._updateProposal(values)
    } else {
      this._saveProposal(values)
    }
  }

  _saveProposal = async values => {
    const { job, client, location } = this.state
    const { currentCompany } = this.props

    if (client && location && currentCompany) {
      let proposal = {}
      proposal.merchant = currentCompany._id
      proposal.client = client._id
      proposal.location = location._id
      proposal.formData = values

      if (job) {
        proposal.job = job._id
      }

      const result = await this.props.createProposal(proposal)

      if (result.error) {
        return toast('Unable to Save Proposal')
      }

      if (result.payload && result.payload.data) {
        this.setState({
          proposal: result.payload.data
        })
        this.props.history.push(
          `/dashboard/edit-proposal/${result.payload.data._id}`
        )
      }
      toast('Proposal Saved')
    }
  }

  _updateProposal = async values => {
    let updatedProposal = this.state.proposal
    updatedProposal.formData = values
    const result = await this.props.updateProposal(updatedProposal)

    if (result.error) {
      return toast('Unable to Update Proposal')
    }

    this.props.history.push(
      `/dashboard/edit-proposal/${result.payload.data._id}`
    )
    toast('Proposal Updated')
  }

  _updateProposalName = async values => {
    if (!values) {
      return null
    }

    let updatedProposal = this.state.proposal
    updatedProposal.name = values.name
    const result = await this.props.updateProposal(updatedProposal)

    this.props.history.push(
      `/dashboard/edit-proposal/${result.payload.data._id}`
    )
  }

  _applyJanitorialTemplate = async () => {
    const { values, change } = this.props
    const { job, client, location } = this.state

    let taskSections = values.taskSections // Form Value for taskSections

    if (job) {
      taskSections = taskSections.concat(areasToTasks(job.areas))
    } else if (!job && client && location) {
      taskSections = taskSections.concat(
        await this._locationToTasks(location.type)
      )
    }
    change('Proposal', 'taskSections', taskSections)
    change('Proposal', 'janitorialTemplateApplied', true)
  }

  _applyServiceTemplate = async (service, fieldName) => {
    const { values, change } = this.props

    if (!service) {
      return
    }

    let taskSections = values.taskSections // Form Value for taskSections
    taskSections = taskSections.concat({
      name: service.name,
      tasks: service.tasks
    })

    change('Proposal', 'taskSections', taskSections)
    change('Proposal', fieldName, true)
  }

  _autosaveProposal = async values => {
    const { proposal } = this.state
    if (!proposal) {
      return
    }

    let updatedProposal = {
      ...proposal
    }
    updatedProposal.formData = values

    const result = await this.props.updateProposal(updatedProposal)

    if (result.error) {
      this.setState({
        autosaving: false
      })
      return
    }
    this.setState({
      autosaving: false,
      autosaved: true,
      proposal: result && result.payload && result.payload.data
    })
    this.eraseSaved = setTimeout(() => {
      this.setState({
        autosaved: false
      })
    }, 1000)
  }

  _onProposalFormChange = () => {
    if (!this.state.autosaving) {
      this.setState({
        autosaving: true
      })
    }
  }

  onTrash = async () => {
    const { proposal } = this.state
    if (!proposal) {
      return null
    }
    await this.props.archiveProposal(proposal._id)
    this.props.history.push('/dashboard/proposals')
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({
      showModal: !showModal
    })
  }

  autosaveEstimateCharges = async () => {
    if (!this.props.values) {
      return
    }
    await this._autosaveProposal(this.props.values)
    this.setState({
      estimateResults: null
    })
  }

  _toggleCloneModal = () => {
    const { showCloneModal } = this.state
    this.setState({
      showCloneModal: !showCloneModal
    })
  }

  _cloneProposal = async values => {
    const res = await this.props.cloneProposal(values)

    if (res.error) {
      toast('Proposal could not be cloned.')
      return
    }
    if (res && res.payload && res.payload.data) {
      this.setState(
        {
          proposal: res.payload.data,
          client: res.payload.data.client,
          location: res.payload.data.location,
          code: res.payload.data.code,
          showCloneModal: false
        },
        () => {
          this.props.history.push(
            `/dashboard/edit-proposal/${res.payload.data._id}`
          )
          toast('Proposal successfully cloned.')
        }
      )
    }
  }

  _markAsSent = async proposal => {
    let result = await this.props.updateProposal({
      _id: proposal._id,
      sent: !proposal.sent
    })

    if (result.error) {
      return networkErrorToast(result)
    } else if (result && result.payload && result.payload.data) {
      result.payload.data.sent
        ? toast(`Proposal marked as sent.`)
        : toast(`Proposal no longer marked as sent.`)
      this.setState({
        proposal: result.payload.data
      })
    }
  }

  _markAsWon = async proposal => {
    let result = await this.props.updateProposal({
      _id: proposal._id,
      won: !proposal.won
    })

    if (result.error) {
      return networkErrorToast(result)
    } else if (result && result.payload && result.payload.data) {
      result.payload.data.won
        ? toast(`Proposal marked as won.`)
        : toast(`Proposal no longer marked as won.`)
      this.setState({
        proposal: result.payload.data
      })
      if (result.payload.data.won) {
        amplitudeInstance &&
          amplitudeInstance.logEvent('Proposal Marked As Won', {
            id: result.payload.data._id
          })
      }
    }
  }

  render() {
    const { currentCompany, values } = this.props
    const {
      client,
      location,
      job,
      proposal,
      code,
      showModal,
      autosaving,
      autosaved,
      estimateResults
    } = this.state
    let initialTaskSections = []
    if (job && job.tasks && job.tasks.length) {
      let tasks = job.tasks.map(task => {
        return { name: task.name, frequency: 'Daily' }
      })
      initialTaskSections.push({
        name: 'General',
        tasks
      })
    }

    if (values && values.estimateResults && estimateResults) {
      this.autosaveEstimateCharges()
    }

    let initialProposal
    let clientName = client && client.name
    let locationAreas = location && location.areas

    if (proposal && proposal.formData) {
      initialProposal = proposal.formData
    } else {
      initialProposal = {
        showHeaderSection: true,
        includeAreaSqFt: true,
        includeAreaImages: false,
        includeAreaNotes: false,
        includeAreaFixtures: false,
        showProviderLogo: true,
        showClientLogo: true,
        showFrequency: true,
        showCharges: true,
        showTaskSchedule: true,
        showLegal: true,
        showAdditionalTerms: true,
        showNotes: false,
        notes: job && job.notes,
        showAreas: false,
        showFixtureAggregate: false,
        serviceFrequency: 'Daily',
        providerLogo: currentCompany && currentCompany.logo,
        clientLogo: client && client.logo,
        clientAddress:
          location && location.address && location.address.fullAddress,
        taskSections: initialTaskSections,
        headerColor: { r: '225', g: '225', b: '225', a: '1' },
        headerTextColor: { r: '0', g: '0', b: '0', a: '1' },
        showEstimateCharges: true,
        estimateCharges: estimateResults,
        estimateResults,
        showSignature: true
      }

      if (job && job.areas && job.areas.length > 0) {
        initialProposal.areas = {}
        job.areas.forEach(
          area => (initialProposal.areas[`area${area._id}`] = true)
        )
      } else if (location && location.areas && location.areas.length > 0) {
        initialProposal.areas = {}
        location.areas.forEach(
          area => (initialProposal.areas[`area${area._id}`] = true)
        )
      }
    }

    let preview = ''

    // preview += md.provider(currentCompany)
    if (values && values.showHeaderSection) {
      preview += md.header({
        values,
        job,
        currentCompany,
        client,
        proposal,
        code
      })
    }

    if (
      values &&
      values.showEstimateCharges &&
      values.estimateCharges &&
      values.estimateCharges.length
    ) {
      preview += md.estimateCharges(
        values.estimateCharges,
        values.showChargesTotals
      )
    }

    if (values && values.showCharges && values.charges) {
      preview += md.charges(values)
    }

    if (values && values.showFrequency) {
      preview += md.serviceFrequency(values)
    }

    const _showNotes = values && values.showNotes && values.notes

    if (_showNotes) {
      preview += `<h2 class="section-title">Notes</h2> ${md.newline}`
      preview += `${values.notes}`
    }

    const _showTasks =
      values &&
      values.showTaskSchedule &&
      values.taskSections &&
      values.taskSections.length > 0

    const _showAreas =
      values &&
      values.showAreas &&
      ((job && job.areas && job.areas.length > 0) ||
        (locationAreas && locationAreas.length > 0))

    const _showFixtures = values && values.showFixtureAggregate

    const _showScopeOfWork = _showTasks || _showAreas

    if (_showScopeOfWork) {
      if (_showTasks) {
        preview += md.taskSections(values)
      }
      if (_showAreas) {
        let areas
        if (job && job.areas && job.areas.length > 0) {
          areas = job && job.areas
        } else if (locationAreas && locationAreas.length > 0) {
          areas = locationAreas
        }
        areas = areas.filter(
          area => values && values.areas && values.areas[`area${area._id}`]
        )
        preview += md.areas(
          areas,
          values.includeAreaImages,
          values.includeAreaNotes,
          values.includeAreaFixtures,
          values.includeAreaSqFt
        )
      }
    }

    if (_showFixtures) {
      let areas
      if (job && job.areas && job.areas.length > 0) {
        areas = job && job.areas
      } else if (locationAreas && locationAreas.length > 0) {
        areas = locationAreas
      }
      preview += md.fixtures(areas)
    }
    preview += `${md.linebreak}`

    if (values && values.showLegal && values.legal) {
      preview += md.legalTerms(values)
    }

    if (values && values.showAdditionalTerms && values.additionalTerms) {
      preview += md.additionalTerms(values)
    }

    if (
      values &&
      (values.showSignature || values.showSignature === undefined)
    ) {
      preview += md.signatureLine(
        clientName,
        currentCompany.name,
        values ? values.title : 'Service Proposal'
      )
    }

    return (
      <Container fluid>
        {proposal && proposal._id && (
          <CloneForm
            open={this.state.showCloneModal}
            toggle={this._toggleCloneModal}
            header={`Clone Proposal ${proposal.code}`}
            onSubmit={this._cloneProposal}
            initialValues={{
              proposal: proposal._id,
              client: proposal.client && proposal.client._id,
              location: proposal.location && proposal.location._id
            }}
          />
        )}
        <Modal
          isOpen={showModal}
          toggle={this.toggleModal}
          style={{ paddingTop: 50 }}
        >
          <ModalHeader style={{ justifyContent: 'center' }}>
            {'Email Proposal'}
          </ModalHeader>
          <ModalBody>
            <EmailForm
              onSubmit={this._emailProposal}
              busy={this.state.emailButtonBusy}
              initialValues={{ proposal: preview, ccSelf: true }}
              encType="multipart/form-data"
              clientContact={
                client && client.contact && client.contact.email
                  ? client.contact
                  : null
              }
            />
          </ModalBody>
        </Modal>
        <ActionBar
          left={
            <TitleDisplay
              proposal={proposal}
              onUpdate={this._updateProposalName}
            />
          }
          right={
            <div>
              {autosaving ? (
                <AutoSaveIndicator>Saving...</AutoSaveIndicator>
              ) : autosaved ? (
                <AutoSaveIndicator>Saved</AutoSaveIndicator>
              ) : null}
              {proposal && proposal._id && (
                <span>
                  <Icon
                    icon="FaPaperPlane"
                    color={
                      proposal.sent ? '#09f415' : this.props.theme.colors.grey
                    }
                    size={30}
                    style={{ marginRight: 8 }}
                  />
                  <Icon
                    icon="FaMedal"
                    color={
                      proposal.won ? '#09f415' : this.props.theme.colors.grey
                    }
                    style={{ marginRight: 8 }}
                    size={30}
                  />
                </span>
              )}
              <ActionButtons
                buttons={[
                  {
                    title: 'Save Proposal',
                    onClick: () => this._updateProposal(values)
                  }
                ]}
                options={[
                  {
                    title: this.state.downloadButtonBusy ? 'Generating PDF...' : 'Download PDF',
                    onClick: this.state.downloadButtonBusy ? null : () => this._downloadProposal(preview),
                    disabled: this.state.downloadButtonBusy,
                    icon: this.state.downloadButtonBusy ? 'spinner' : null
                  },
                  {
                    title: 'Email Proposal',
                    onClick: () => this.toggleModal()
                  },
                  ...(proposal && proposal._id
                    ? [
                        {
                          title: proposal.sent
                            ? 'Undo Sent Flag'
                            : 'Mark as Sent',
                          onClick: () => this._markAsSent(proposal)
                        },
                        {
                          title: proposal.won ? 'Undo Won Flag' : 'Mark as Won',
                          onClick: () => this._markAsWon(proposal)
                        },
                        {
                          title: 'Clone Proposal',
                          onClick: () => this._toggleCloneModal()
                        },
                        {
                          title: 'Archive Proposal',
                          divide: true,
                          color: 'danger',
                          onClick: () =>
                            this.props.confirm(
                              'Confirm Archive',
                              'Are you sure you want to archive this proposal?',
                              this.onTrash
                            )
                        }
                      ]
                    : [])
                ]}
              />
            </div>
          }
        />
        <Row>
          <ScrollableColumn lg="6">
            <Tab>Edit</Tab>
            <div
              style={{
                backgroundColor: 'white',
                marginTop: 36,
                padding: 10,
                borderRadius: 4
              }}
            >
              <ProposalForm
                applyJanitorialTemplate={this._applyJanitorialTemplate}
                formDisabled={proposal && proposal.won}
                initialValues={initialProposal}
                enableReinitialize
                job={job}
                client={client}
                clientLocation={location}
                currentCompany={currentCompany}
                data={this.props.values}
                onAutoSave={() => this._autosaveProposal(values)}
                onProposalFormChange={this._onProposalFormChange}
                applyServiceTemplate={this._applyServiceTemplate}
                {...this.props}
              />
            </div>
          </ScrollableColumn>
          <ScrollableColumn lg="6">
            <Tab>Preview</Tab>
            <div
              style={{
                minHeight: 500,
                backgroundColor: 'white',
                marginTop: 36,
                padding: 10,
                borderRadius: 4
              }}
            >
              <ProposalPreview preview={preview} />
            </div>
          </ScrollableColumn>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  jobs:
    state.jobs.jobs && state.jobs.jobs.length
      ? state.jobs.jobs.filter(job => job.type === 'Walkthrough')
      : [],
  values: state.form.Proposal && state.form.Proposal.values,
  currentCompany: state.user.currentCompany,
  templates: state.templates.templates
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ change, ...api }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(connectConfirmation(Proposal)))

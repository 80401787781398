import React, { useState, memo, useContext, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Cell, PieChart, Pie, ResponsiveContainer, Tooltip } from 'recharts';
import numeral from 'numeral';
import { removeSmallData, renderCustomPieChartLabel, renderCustomPieChartLabelLine } from '../util'
import { PieLabelArray } from './PieLabelArray'

import StatsCard from './StatsCard'
import MotivationalMessage from './MotivationalMessage'

const containerStyles = `
  grid-row-start: 3;
  grid-row-end: span 2;

  @media (max-width: 700px) {
    grid-row-start: 5;
    grid-row-end: span 2;
  }
`

const formatTooltipLabel = (value, name, props) => [numeral(value).format('$0,0[.]00'), name]

export const DistributionOfRevenue = memo(({ stats, tooltipText }) => {
  const theme = useContext(ThemeContext);
  const bluePalette = theme.dashboard.chartSalesGradient || [];

  const sortedData = useMemo(() => {
    let data = [];
    if (stats) {
      data = Object.keys(stats).map(key => {
        const value = stats[key] || 0
        return {name: key, value}
      })
      data.sort((a, b) => b.value - a.value);
    }
    return data;
  }, [stats]);

  const shortLabelsLength = 3

  return (
    <StatsCard
      title={'Distribution of Revenue'}
      containerStyles={containerStyles}
      tooltipText={tooltipText}
    >
      {sortedData.length ? (
        <>
          <div style={{ width: '100%' }}>
            <ResponsiveContainer height={200} width="100%">
              <PieChart key={Math.random()}>
                <Pie
                  dataKey="value"
                  data={sortedData}
                  cx="50%"
                  cy="50%"
                  innerRadius={5}
                  outerRadius={50}
                  label={(props) => renderCustomPieChartLabel({...props, theme})}
                  labelLine={(props) => renderCustomPieChartLabelLine({...props, theme})}
                  paddingAngle={0}
                  startAngle={90}
                  endAngle={-270}
                >
                  {sortedData.map((entry, index) => {
                    const colorIndex = index % bluePalette.length;
                    const fillColor = bluePalette[colorIndex];
                    return (
                      <Cell key={`cell-${index}`} fill={fillColor} stroke={fillColor} strokeWidth={1}/>
                    )
                  })}
                </Pie>
                <Tooltip formatter={formatTooltipLabel} />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <PieLabelArray elementId="dist-of-rev-labels" data={sortedData} shortenTo={shortLabelsLength} colorPalette={bluePalette} />
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <MotivationalMessage message={'Nothing yet, go get those sales!'} />
        </div>
      )}
    </StatsCard>
  )
})
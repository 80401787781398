import React from 'react'
import styled from 'styled-components'

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1rem;
`

const MessageText = styled.p`
  color: ${props => props.theme.dashboard.metricTitle || '#ADADAD'};
  font-size: 1rem;
  text-align: center;
`

const MotivationalMessage = ({message}) => {
  return (
    <MessageContainer>
      <MessageText>
        {message}
      </MessageText>
    </MessageContainer>
  )
}

export default MotivationalMessage
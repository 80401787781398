import React, { useState, memo, useContext, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'
import StatsCard from './StatsCard'
import { Cell, PieChart, Pie, ResponsiveContainer, Tooltip } from 'recharts';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { capitalize } from 'utils'
import numeral from 'numeral';
import MotivationalMessage from './MotivationalMessage'
import { PieLabelArray } from './PieLabelArray'
import { removeSmallData, renderCustomPieChartLabel, renderCustomPieChartLabelLine } from '../util'

const DropdownContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
`

const containerStyles = `
  grid-row-start: 3;
  grid-row-end: span 2;
`

const locationTypes = ['cities', 'states', 'countries']

const formatTooltipLabel = (value, name, props) => [numeral(value).format('$0,0[.]00'), name]

export const SalesByLocation = memo(({ stats, merchantCity, tooltipText }) => {
  const [ locationType, setLocationType ] = useState('cities')
  const theme = useContext(ThemeContext);
  const bluePalette = theme.dashboard.chartSalesGradient || [];

  const sortedData = useMemo(() => {
    let processedData = [];
    if (stats && stats[locationType]) {
      processedData = Object.keys(stats[locationType]).map(key => {
        const value = stats[locationType][key] || 0
        return {name: key, value}
      })
      processedData = [...processedData].sort((a, b) => b.value - a.value);
    }
    return processedData;
  }, [stats, locationType]);

  return (
    <StatsCard
      title={'Sales by Location'}
      containerStyles={containerStyles}
      tooltipText={tooltipText}
    >
      {sortedData?.length ? (
        <>
          <DropdownContainer>
            <UncontrolledDropdown>
              <DropdownToggle style={{fontSize: '0.875rem'}} color='primary' caret>{capitalize(locationType)}</DropdownToggle>
              <DropdownMenu right={true}>
                {locationTypes.map((type, index) => (
                  <DropdownItem style={{fontSize: '0.875rem'}} key={`location-type-${index}`} onClick={() => setLocationType(type)}>{capitalize(type)}</DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </DropdownContainer>
          <div style={{ width: '100%' }}>
            <ResponsiveContainer height={200} width="100%">
              <PieChart key={Math.random()}>
                <Pie
                  dataKey="value"
                  data={sortedData}
                  cx="50%"
                  cy="50%"
                  innerRadius={5}
                  outerRadius={50}
                  label={(props) => renderCustomPieChartLabel({...props, theme})}
                  labelLine={(props) => renderCustomPieChartLabelLine({...props, theme})}
                  paddingAngle={0}
                  startAngle={90}
                  endAngle={-270}
                >
                  {sortedData.map((entry, index) => {
                    const colorIndex = index % bluePalette.length;
                    const fillColor = bluePalette[colorIndex];
                    return (
                      <Cell key={`cell-${index}`} fill={fillColor} stroke={fillColor} strokeWidth={1}/>
                    )
                  })}
                </Pie>
                <Tooltip formatter={formatTooltipLabel} />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <PieLabelArray data={sortedData} shortenTo={3} elementId={'sales-by-location-labels'} colorPalette={bluePalette} />
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          No data found
        </div>
      )}
    </StatsCard>
  )
})
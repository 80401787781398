import React from 'react'

import { ScriptCache } from './lib/ScriptCache'
import GoogleApi from './lib/GoogleApi'

const serialize = obj => JSON.stringify(obj)
const isSame = (obj1, obj2) =>
  obj1 === obj2 || serialize(obj1) === serialize(obj2)

const defaultCreateCache = options => {
  options = options || {}
  const apiKey = options.apiKey
  const libraries = options.libraries || ['places']
  const version = options.version || '3'
  const language = options.language || 'en'
  const url = options.url
  const client = options.client
  const region = options.region

  return ScriptCache({
    google: GoogleApi({
      apiKey: apiKey,
      language: language,
      libraries: libraries,
      version: version,
      url: url,
      client: client,
      region: region
    })
  })
}

const DefaultLoadingContainer = props => <div>Loading...</div>

export const GoogleApiWrapper = input => WrappedComponent => {
  class Wrapper extends React.Component {
    state = {
      loaded: false,
      google: null,
      options: typeof input === 'function' ? input(this.props) : input
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      // Re-calculate options if input is a function
      const options = typeof input === 'function' ? input(nextProps) : input;

      // If options changed, reset loaded state and update options in state
      if (!isSame(options, prevState.options)) {
        return {
          loaded: false,
          google: null,
          options: options
        };
      }
      // Otherwise, no state change needed
      return null;
    }

    componentDidMount() {
      this.initialize(this.state.options);
    }

    componentDidUpdate(prevProps, prevState) {
      // If options changed (detected by getDerivedStateFromProps updating state.options),
      // re-initialize
      if (!isSame(prevState.options, this.state.options)) {
        this.initialize(this.state.options);
      }
    }

    initialize(options) {
      // Avoid race condition: remove previous 'load' listener
      if (this.unregisterLoadHandler) {
        this.unregisterLoadHandler()
        this.unregisterLoadHandler = null
      }

      // Load cache factory
      const createCache = options.createCache || defaultCreateCache

      // Build script
      this.scriptCache = createCache(options)
      this.unregisterLoadHandler = this.scriptCache.google.onLoad(
        this.onLoad.bind(this)
      )

      // Store information about loading container
      this.LoadingContainer =
        options.LoadingContainer || DefaultLoadingContainer
    }

    onLoad(err, tag) {
      this._gapi = window.google

      this.setState({ loaded: true, google: this._gapi })
    }

    render() {
      const { LoadingContainer } = this
      if (!this.state.loaded) {
        return <LoadingContainer />
      }

      const props = Object.assign({}, this.props, {
        loaded: this.state.loaded,
        google: window.google
      })

      return (
        <React.Fragment>
          <WrappedComponent {...props} />
          <div />
        </React.Fragment>
      )
    }
  }

  return Wrapper
}

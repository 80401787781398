import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { setSalesTarget } from 'api'
import numeral from 'numeral';

import moment from 'moment'

import { fetchSales } from 'api'

import {
  AverageContractValue,
  AverageInspectionRating,
  ConversionRate,
  DistributionOfRevenue,
  InspectionMetrics,
  SalesByLocation,
  SalesByLocationType,
  SalesLeaderboard,
  SalesTarget,
  SimpleMetricCard,
  Tally,
  TimeBar,
  SkeletonCard,
} from './components'
import { calculateRevenueStats } from './util'

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 1rem;
  margin-top: 0.9375rem;
`

const dateRangeOptions = [
  {
    name: '1w',
    startDate: moment()
      .subtract(7, 'days')
      .format(),
    endDate: moment().format()
  },
  {
    name: '1m',
    startDate: moment()
      .subtract(1, 'months')
      .format(),
    endDate: moment().format()
  },
  {
    name: '3m',
    startDate: moment()
      .subtract(3, 'months')
      .format(),
    endDate: moment().format()
  },
  {
    name: '6m',
    startDate: moment()
      .subtract(6, 'months')
      .format(),
    endDate: moment().format()
  },
  {
    name: '1y',
    startDate: moment()
      .subtract(1, 'years')
      .format(),
    endDate: moment().format()
  },
  { name: 'All', startDate: null, endDate: null }
]

const SalesDashboard = () => {
  const [currentTimeInterval, setCurrentTimeInterval] = useState(5)
  const [revenueStats, setRevenueStats] = useState({})
  const dispatch = useDispatch()
  const { currentCompany, currentEmployment } = useSelector(state => state.user)

  const requesting = useSelector(state => state.sales.requesting)
  const stats = useSelector(state => state.sales.stats)

  useEffect(() => {
    setRevenueStats(calculateRevenueStats(stats?.wonProposals))
  }, [stats?.wonProposals])

  const getStats = async (startDate, endDate) => {
    try {
      await dispatch(fetchSales(startDate, endDate))
    } catch (error) {
      // networkErrorToast(error)
    }
  }

  useEffect(() => {
    const _initialLoad = async () => {
      let item = dateRangeOptions[5]
      await getStats(item.startDate, item.endDate)
    }

    _initialLoad()
  }, [dispatch])

  const _submitSalesTarget = async value => {
    dispatch(setSalesTarget({ salesTarget: value }))
  }

  const _changeDateRange = async item => {
    await getStats(item.startDate, item.endDate)
    setCurrentTimeInterval(dateRangeOptions
      .map(d => d.name)
      .indexOf(item.name)
    )
  }

  const conversionRateStats = {
    created: stats?.counts?.proposalCount?.created || 0,
    won: stats?.counts?.business?.dealsWon || 0,
  }

  // Helper to generate skeleton loaders
  const renderSkeletons = (count = 16) => {
    return Array.from({ length: count }).map((_, index) => <SkeletonCard key={`skeleton-${index}`} />);
  };

  return (
    <div style={{ padding: '0.5rem 3.75rem', display: 'flex', flexDirection: 'column' }}>
      <TimeBar
        data={dateRangeOptions}
        defaultValue={dateRangeOptions[currentTimeInterval]}
        onChange={_changeDateRange}
      />
      <StatsContainer>
        {requesting ? (
          renderSkeletons() // Show skeletons while requesting data
        ) : (
          // Render actual components when data is loaded
          <>
            <SimpleMetricCard
              title="New Leads"
              value={stats?.counts?.leads}
              tooltipText="Count of unique Locations associated with non-won Walkthroughs or Proposals created in the selected period."
            />
            <SimpleMetricCard
              title="Missed Opportunities"
              value={stats?.counts?.declinedProposals}
              tooltipText="Count of Proposals marked as 'Declined' in the selected period."
            />
            <SimpleMetricCard
              title="Total Sales"
              value={revenueStats?.yearlyStats?.newSales}
              prefix="$"
              tooltipText="Sum of 'Yearly Total Revenue' from all Proposals marked as 'Won' in the selected period."
            />
            <ConversionRate
              stats={conversionRateStats}
              tooltipText="Percentage of Proposals marked 'Won' out of all Proposals created in the selected period. (Won / Created) * 100"
            />
            <SalesTarget
              monthlyContracts={stats?.monthlyContracts}
              updateSalesTarget={_submitSalesTarget}
              salesTarget={currentCompany?.analyticsSettings?.salesTarget}
              employeeRole={currentEmployment?.role}
              tooltipText="Comparison of total 'Yearly Revenue' from Proposals won this calendar month against the set monthly target."
            />
            <AverageContractValue
              average={revenueStats?.yearlyStats?.averageContractValue}
              count={stats?.wonProposals?.length}
              tooltipText="Average 'Yearly Total Revenue' across all Proposals marked 'Won' in the selected period. (Total Sales / Won Proposals)"
            />
            <SalesByLocation
              stats={revenueStats?.yearlyStats?.salesByLocation}
              merchantCity={currentCompany?.address?.city}
              tooltipText="Breakdown of Total Sales revenue by the location (City, State, or Country) associated with won Proposals in the selected period."
            />
            <DistributionOfRevenue
              stats={revenueStats?.yearlyStats?.salesByService}
              tooltipText="Breakdown of Total Sales revenue by Service Type based on won Proposals in the selected period."
            />
            <SalesByLocationType
              stats={revenueStats?.yearlyStats?.salesByLocationType}
              tooltipText="Breakdown of Total Sales revenue by Location Type (e.g., Office, Church) based on won Proposals in the selected period."
            />
            <SalesLeaderboard
              stats={revenueStats?.yearlyStats?.salesLeaderboard}
              tooltipText="Ranking of users by the total 'Yearly Revenue' from Proposals they created that were marked 'Won' in the selected period."
            />
            <InspectionMetrics
              stats={stats?.counts?.inspectionCount}
              tooltipText="Count of Inspections created vs. completed in the selected period. Percentage is (Completed / Created) * 100. Completion based on schedule date."
            />
            <AverageInspectionRating
              inspections={stats?.completedInspections}
              tooltipText="Average score across completed Inspections in the period. Each score is (% of 'Great' areas / ('Great' + 'Needs Improvement' areas))."
            />
            <SimpleMetricCard
              title="Total Sq. Ft. Managed"
              value={revenueStats?.yearlyStats?.totalSqFt}
              formatter={(val) => numeral(val).format('0,0')}
              tooltipText="Sum of 'Total Sq. Ft.' from all Proposals marked as 'Won' in the selected period."
            />
            <Tally
              stats={stats?.counts?.walkthroughCount}
              title="Walkthrough"
              tooltipText="Count of Walkthroughs created vs. completed in the selected period. Completion based on schedule date."
            />
            <Tally
              stats={stats?.counts?.estimateCount}
              title="Estimates"
              tooltipText="Count of Estimates created in the selected period."
            />
            <Tally
              stats={stats?.counts?.proposalCount}
              title="Proposals"
              tooltipText="Count of Proposals created vs. sent in the selected period."
            />
            <Tally
              stats={stats?.counts?.business}
              title="New Business"
              tooltipText="Count of Proposals marked 'Won' vs. count of new Clients created in the selected period."
            />
          </>
        )}
      </StatsContainer>
    </div>
  )
}

export default SalesDashboard

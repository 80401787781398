import scssVariables from '../styles/_variables.scss'
import makeTheme from './makeTheme'

const variables = {
  colors: {
    black: '#4D4D4D',
    white: '#FFF',

    clarity: scssVariables.clarity,
    primary: scssVariables.primary,

    secondary: '#2e3151',
    alert: '#FFCC00',

    success: 'rgb(0, 255, 0)',
    warning: '#FF502D',
    error: 'rgb(234, 12, 0)',
    danger: 'rgb(234, 12, 0)',

    dark2: '#3D3E41',
    dark1: '#2E2F31',
    dark0: '#1E1F22',

    grey: '#8c91ab',

    light0: '#fff',
    light1: '#f6f7f7',
    light2: '#d3d4d5'
  },
  timebar: {
    background: '#191919',
    label: '#757575',
    activeLabel: '#FFF'
  },
  dashboard: {
    titleColor: '#FFFFFF',
    cardBackground: '#191919',
    cardHeaderBackground: '#000000',
    cardTitle: '#FFFFFF',
    metricTitle: '#ADADAD',
    metric: 'rgb(255, 255, 255)',
    chartPrimary: '#3B82F6',
    chartSecondary: '#F97316',
    chartTertiary: '#2DD4BF',
    chartAccent: '#22C55E',
    chartOther: '#6366F1',
    chartSalesGradient: [
      '#1A2A7A',
      '#2A55AF',
      '#B06AE0',
      '#EA80BB',
      '#D25068',
      '#E79556'
    ]
  }
}

const theme = makeTheme(variables, null)

export default theme

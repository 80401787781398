import React from 'react';
import styled from 'styled-components';
import StatsCard from './StatsCard'; // Assuming StatsCard is the common card wrapper

const Metric = styled.p`
  text-align: center;
  color: ${props => props.theme.dashboard.metric};
  font-size: 2.5rem;
  align-self: center; // Added to center horizontally within the flex column
`;

const Prefix = styled.span`
  font-size: 1.5rem;
  color: ${props => props.theme.dashboard.metric};
`;

const Suffix = styled.span`
  font-size: 1.5rem;
  color: ${props => props.theme.dashboard.metric};
`;

export const SimpleMetricCard = ({ title, value, prefix, suffix, formatter, tooltipText }) => {
  const displayValue = formatter ? formatter(value) : (value || 0);

  return (
    <StatsCard title={title} tooltipText={tooltipText}>
      <Metric>
        {prefix && <Prefix>{prefix}</Prefix>}
        {displayValue}
        {suffix && <Suffix>{suffix}</Suffix>}
      </Metric>
    </StatsCard>
  );
}; 
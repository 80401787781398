import React, { Component } from 'react'
import { connect } from 'react-redux'
import { destroy } from 'redux-form'
import { Card, Row, Col, Button } from 'reactstrap'
import styled from 'styled-components'
import _ from 'lodash'
import { toast } from 'react-toastify'
import * as actions from 'api'
import createEstimate from 'assets/img/create-estimate.svg'
import SelectWalkthroughForm from './SelectWalkthroughForm'
import SelectClientForm from './SelectClientForm'
import SelectServiceForm from './SelectServiceForm'
//import SelectChargeTypeForm from './SelectChargeTypeForm'
import { jobStatus } from 'utils'

const StyledImg = styled.img`
  width: 100%;
`

class NewEstimate extends Component {
  constructor(props) {
    super(props);

    // Initialize state, potentially using props.history.location.state
    let page = 1;
    let initialValues = {};
    let selectedForm = undefined;
    let hls = props?.history?.location?.state;

    if (hls) {
      initialValues = {
        job: hls.job,
        client: hls.clientId,
        location: hls.locationId
      };
      selectedForm = (hls.job && 'walkthrough') || (hls.clientId && 'client');
      page = 3;
    }

    this.state = {
      page: page,
      initialValues: initialValues,
      selectedForm: selectedForm
    };
  }

  async componentDidMount() {
    await this.props.getWalkthroughs({
      completedAt: { $exists: true },
      completedBy: { $exists: true }
    })
    await this.props.getServiceTemplates()
    await this.props.getClients()
  }

  _nextPage = values => {
    this.setState({ page: this.state.page + 1 })
  }

  _previousPage = () => {
    const { page, selectedForm } = this.state
    if (page === 3 && selectedForm === 'service') {
      this.setState({ page: 1 })
    } else {
      this.setState({ page: this.state.page - 1 })
    }
  }

  _onSubmit = async values => {
    const { selectedForm } = this.state
    if (!values) {
      return null
    }

    if (!values.services) {
      toast('Please select at least one service.')
      return
    }

    let finalValues = {
      services:
        values &&
        values.services &&
        values.services.map(service => {
          return {
            service: service._id,
            data: {}
          }
        })
      //      chargeType: values && values.chargeType && values.chargeType.id
    }

    if (selectedForm === 'walkthrough') {
      finalValues.jobId = values && values.job && values.job._id
    } else if (selectedForm === 'client') {
      finalValues.clientId = values && values.client
      finalValues.locationId = values && values.location
    }

    await this.props.history.push(`/dashboard/edit-estimate`, finalValues)
    this.props.destroy('NewEstimate')
  }

  render() {
    const { services, values, clients } = this.props
    const { page, initialValues, selectedForm } = this.state
    const completedWalkthroughs = _.filter(this.props.walkthroughs, function(
      walkthrough
    ) {
      return jobStatus(walkthrough) === 'completed'
    })

    if (values) {
      if (values.job && values.job.categories && values.job.categories.length) {
        initialValues.job = values.job

        initialValues.services = initialValues.job.categories.map(category =>
          _.find(services, {
            name: category
          })
        )
      }
      if (values.services && values.services.length) {
        if (!initialValues.services) {
          initialValues.services = values.services
        }
      }
    } else if (!values && initialValues) {
      if (
        initialValues.job &&
        initialValues.job.categories &&
        initialValues.job.categories.length
      ) {
        initialValues.services = initialValues.job.categories.map(category =>
          _.find(services, {
            name: category
          })
        )
      }
    }

    return (
      <div style={{ padding: 100 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'middle',
            flex: 1
          }}
        >
          <Card
            body
            style={{
              maxWidth: 800,
              paddingLeft: 50,
              paddingTop: 40,
              marginRight: -75,
              marginLeft: -75
            }}
          >
            <h3 className="text-dark">Create Estimate</h3>

            <Row style={{ marginTop: 30 }}>
              <Col xs="12" md="7" style={{ paddingTop: 10 }}>
                {page === 1 && (
                  <>
                    <Button
                      block
                      type="submit"
                      color="info"
                      style={{ marginBottom: 24 }}
                      onClick={() =>
                        this.setState({ page: 2, selectedForm: 'walkthrough' })
                      }
                    >
                      Use a completed walkthrough
                    </Button>
                    <h6 style={{ textAlign: 'center' }}> - OR - </h6>
                    <Button
                      block
                      type="submit"
                      color="info"
                      style={{ marginTop: 24, marginBottom: 24 }}
                      onClick={() =>
                        this.setState({ page: 2, selectedForm: 'client' })
                      }
                    >
                      Select a client and location
                    </Button>
                    <h6 style={{ textAlign: 'center' }}> - OR - </h6>
                    <Button
                      block
                      type="submit"
                      color="info"
                      style={{ marginTop: 24 }}
                      onClick={() =>
                        this.setState({ page: 3, selectedForm: 'service' })
                      }
                    >
                      Select services
                    </Button>
                  </>
                )}
                {page === 2 && (
                  <>
                    {selectedForm === 'walkthrough' && (
                      <SelectWalkthroughForm
                        jobs={completedWalkthroughs}
                        onSubmit={this._nextPage}
                        goBack={this._previousPage}
                      />
                    )}
                    {selectedForm === 'client' && (
                      <SelectClientForm
                        clients={clients}
                        onSubmit={this._nextPage}
                        goBack={this._previousPage}
                      />
                    )}
                  </>
                )}
                {page === 3 && (
                  <SelectServiceForm
                    initialValues={initialValues}
                    services={services}
                    onSubmit={this._onSubmit}
                    goBack={this._previousPage}
                  />
                )}
              </Col>
              <Col xs="12" md="5">
                <StyledImg
                  src={createEstimate}
                  alt="create proposal"
                  style={{ padding: 16 }}
                />
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    clients: state.clients.clients,
    walkthroughs: state.walkthroughs.walkthroughs,
    services: state.library.services,
    values: state.form.NewEstimate ? state.form.NewEstimate.values : null
  }),
  { ...actions, destroy }
)(NewEstimate)

import React from 'react'
import styled from 'styled-components'
import StatsCard from './StatsCard'

const Metric = styled.p`
  text-align: center;
  color: ${props => props.theme.dashboard.metric};
  font-size: 2.5rem;
  align-self: center;
`

const SmallSpan = styled.span`
  font-size: 1.5rem;
  color: ${props => props.theme.dashboard.metric};
`

const CountText = styled.p`
  text-align: center;
  color: ${props => props.theme.dashboard.metric};
  font-size: 0.75rem;
  align-self: center;
`

export const AverageContractValue = ({ average, count, tooltipText }) => {
  const countString = count > 1 ? `${count} Total Contracts` : count === 1 ? `${count} Contract` : 'No Contracts'
  
  return (
    <StatsCard title={'Average Contract Value'} tooltipText={tooltipText}>
      <Metric><SmallSpan>$</SmallSpan>{average || 0}</Metric>
      <CountText>{countString}</CountText>
    </StatsCard>
  )
}

import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Field,
  FieldArray,
  change,
  FormSection,
  getFormValues
} from 'redux-form'
import { Card, CardBody, Button, Row, Col } from 'reactstrap'
import { CheckboxField, InlineInputField } from 'components/fields'
import TabularField from './TabularField'
import _ from 'lodash'
import styled from 'styled-components'
import { floorTypeSubtotals, fixtureCounter } from 'utils'
import InfoPopover from './InfoPopover'

const InputField = ({ style, ...rest }) => (
  <InlineInputField
    rowStyle={{ marginTop: 0, marginBottom: 8, ...style }}
    {...rest}
  />
)

const MiniButton = styled(Button)`
  background-color: ${props =>
    props.bgColor ? props.bgColor : 'white'} !important;
  ${props => (props.hideBorder ? '' : 'border: 1px solid #ddd !important;')}
  color: ${props => (props.color ? props.color : '#444')} !important;
  font-size: 12px !important;
  padding: 4px 8px !important;
  height: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    ${props =>
      props.hoverColor
        ? 'background-color: ' + props.hoverColor + ' !important;'
        : ''}
  }

  &:active {
    box-shadow: 0 0px 1px rgba(0, 0, 0, 0.04);
  }
`

const FloorTypeFields = ({ fields, values, rows, title }) => {
  let floorTypeValues =
    values &&
    values.workloader &&
    values.workloader &&
    values.workloader.floorTypeData

  return (
    <div>
      <div
        style={{
          maxHeight: 500,
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: 16,
          marginBottom: 8
        }}
      >
        {fields.map((line, index) => {
          return (
            <div key={index}>
              {/* <Field
                name={`${line}.floorType`}
                component={InputField}
              /> */}
              {(rows || []).map((row, rIndex) => {
                return (
                  <div key={rIndex}>
                    <Field
                      label={row.placeholder}
                      name={`${line}.${row.name}`}
                      type={row.type || 'text'}
                      component={InputField}
                      {...row.props}
                    />
                  </div>
                )
              })}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <p class="font-weight-bold">Estimated man hours</p>
                <p class="font-weight-bold">
                  {(
                    floorTypeValues[index].sqFt /
                      floorTypeValues[index].prodRate || 0
                  ).toFixed(2)}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <MiniButton
                  hideBorder
                  bgColor="#999"
                  hoverColor="red"
                  color="white"
                  onClick={() => fields.remove(index)}
                >
                  Remove
                </MiniButton>
              </div>
              <hr style={{ height: 1 }} />
            </div>
          )
        })}
      </div>
      <MiniButton onClick={() => fields.push({})}>Add Floor Type</MiniButton>
    </div>
  )
}

const WorkloadResults = ({ label, result }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <h5>{label || 'Result'}</h5>
      <h5>{result || 0}</h5>
    </div>
  )
}

class Workloader extends Component {
  componentDidMount() {
    const { walkthrough } = this.props

    let fixtures = []
    let totalSquareFootage = 0
    let floorSubtotals = {}

    if (walkthrough) {
      const pullFixtures = fixtureCounter(walkthrough.areas)
      fixtures = Object.keys(pullFixtures.types).map(key => {
        return ({ type: key, quantity: pullFixtures.types[key] })
      })
    

      this.props.change('Estimator', 'workloader.fixtureData', fixtures)

      const calcTotals = floorTypeSubtotals(walkthrough.areas)
      totalSquareFootage = calcTotals.total
      floorSubtotals = calcTotals.types
    }

    let floorTypeValues = []
    Object.keys(floorSubtotals).forEach(key => {
      floorTypeValues.push({ floorType: key, sqFt: floorSubtotals[key] })
    })

    floorTypeValues = _.orderBy(floorTypeValues, ['floorType'])

    this.props.change('Estimator', 'workloader.floorTypeData', floorTypeValues)

    totalSquareFootage = totalSquareFootage.toFixed(2)

    if (totalSquareFootage) {
      this.props.change(
        'Estimator',
        'workloader.totalSquareFootage',
        totalSquareFootage
      )
    }
  }

  renderWorkloadSqFt = () => {
    const { values } = this.props
    const { workloadTotalSqFtHours } = this.state

    return (
      <>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 4}}>
          <Field
            name="showFloorTypes"
            component={CheckboxField}
            label="Break out floor types"
            className="pull-left"
          />
          <InfoPopover section="productionRates"  size={20} />
        </div>
        {values && values.workloader && values.workloader.showFloorTypes && (
          <Card>
            <CardBody>
              <FieldArray
                name={'floorTypeData'}
                component={FloorTypeFields}
                rows={[
                  {
                    name: 'floorType',
                    placeholder: 'Floor Type',
                    type: 'text'
                  },
                  {
                    name: 'sqFt',
                    placeholder: 'Square footage',
                    type: 'number'
                  },
                  {
                    name: 'prodRate',
                    type: 'number',
                    placeholder: 'Production rate'
                  }
                ]}
                values={values}
              />
            </CardBody>
          </Card>
        )}
        {values && values.workloader && !values.workloader.showFloorTypes && (
          <Card>
            <CardBody>
              <Field
                name="totalSquareFootage"
                component={InputField}
                type="number"
                label="Total sq. footage"
              />
              <Field
                name="productionRate"
                component={InputField}
                type="number"
                label="Production rate"
              />
            </CardBody>
          </Card>
        )}
        <WorkloadResults
          label="Estimated man hours"
          result={workloadTotalSqFtHours}
        />
      </>
    )
  }

  renderWorkloadFixtures = () => {
    const { values } = this.props
    const { workloadTotalFixtureHours } = this.state

    return (
      <Card>
        <CardBody>
          <Field
            name="showFixtures"
            component={CheckboxField}
            label="Add fixture times"
            className="pull-left"
          />
          {values && values.workloader && values.workloader.showFixtures && (
            <>
              <FieldArray
                name={'fixtureData'}
                component={TabularField}
                maxHeight="356px"
                buttonLabel="Add Fixture"
                columns={[
                  { name: 'type', placeholder: 'Fixture Type', type: 'text' },
                  {
                    name: 'quantity',
                    type: 'number',
                    placeholder: 'Qty.'
                  },
                  {
                    name: 'timePerFix',
                    type: 'number',
                    placeholder: 'Min. per fix.'
                  }
                ]}
              />
              <WorkloadResults
                label="Total Fixture Time (hrs)"
                result={workloadTotalFixtureHours}
              />
            </>
          )}
        </CardBody>
      </Card>
    )
  }

  render() {
    const { values, title } = this.props
    const wlValues = values && values.workloader

    // Calculate workload values directly in render
    let workloadSqFt = 0
    let fixtureTime = 0
    let workloadTotalFixtureHours = 0
    let workloadFinalEstimate = 0

    if (wlValues) {
      if (
        wlValues.showFloorTypes &&
        wlValues.floorTypeData &&
        wlValues.floorTypeData.length
      ) {
        wlValues.floorTypeData.forEach(floorType => {
          if (floorType.sqFt && floorType.prodRate) {
            workloadSqFt += floorType.sqFt / floorType.prodRate
          }
        })
      } else if (wlValues.totalSquareFootage && wlValues.productionRate) {
        // Ensure values are treated as numbers for calculation
        const totalSqFt = parseFloat(wlValues.totalSquareFootage) || 0;
        const prodRate = parseFloat(wlValues.productionRate) || 0;
        if (prodRate !== 0) { // Avoid division by zero
           workloadSqFt = totalSqFt / prodRate;
        }
      }

      if (
        wlValues.showFixtures &&
        wlValues.fixtureData &&
        wlValues.fixtureData.length
      ) {
        wlValues.fixtureData.forEach(fix => {
          if (fix.quantity && fix.timePerFix) {
            // Ensure values are treated as numbers
            const quantity = parseFloat(fix.quantity) || 0;
            const timePer = parseFloat(fix.timePerFix) || 0;
            fixtureTime += quantity * timePer
          }
        })
      }

      workloadTotalFixtureHours = fixtureTime / 60
      workloadFinalEstimate = workloadSqFt + workloadTotalFixtureHours
    }

    const workloadTotalSqFtHours = workloadSqFt; // Use the calculated value directly

    return (
      <FormSection name="workloader">
        <Row>
          <Col>
            <Card>
              <CardBody>{this.renderWorkloadSqFt()}</CardBody>
            </Card>
          </Col>
          <Col>
            {this.renderWorkloadFixtures()}
            <Card>
              <CardBody>
                <WorkloadResults
                  label="Subtotal Fixture Hours"
                  result={workloadTotalFixtureHours.toFixed(2)}
                />
                <hr />
                <WorkloadResults
                  label="Total Estimated Hours"
                  result={workloadFinalEstimate.toFixed(2)}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 16
                  }}
                >
                  <Button
                    color="danger"
                    onClick={this.props.onClose}
                    style={{ marginRight: 16 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.props.onSubmit(workloadFinalEstimate)}
                  >
                    Apply To Estimate
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </FormSection>
    )
  }
}

const mapStateToProps = state => ({
  values: getFormValues('Estimator')(state)
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ change }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Workloader)

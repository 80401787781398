import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css' // Import skeleton styles

// Use similar structure to StatsCard for consistency
const CardContainer = styled.div`
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  background-color: ${props => props.theme.dashboard.cardBackground};
`;

const TitleContainer = styled.div`
  background-color: ${props => props.theme.dashboard.cardHeaderBackground};
  text-align: center;
  padding: 0.5rem 0; // Match StatsCard padding
  display: flex; // Needed for skeleton alignment
  justify-content: center; // Center skeleton
  align-items: center; // Center skeleton
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem; // Match StatsCard content padding
`;

export const SkeletonCard = () => {
  const theme = useContext(ThemeContext); // Use useContext(ThemeContext)

  // Use theme colors for the skeleton
  const baseColor = theme.dashboard.cardBackground || "#191919";
  const highlightColor = theme.colors.dark2 || "#3D3E41";

  return (
    <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
      <CardContainer>
        <TitleContainer>
          <Skeleton height={20} width="60%" style={{ lineHeight: 'initial' }} />
        </TitleContainer>
        <ContentContainer>
          {/* Simple placeholder for content area */}
          <Skeleton height={40} width="40%" style={{ marginBottom: '0.5rem', lineHeight: 'initial' }} />
          <Skeleton height={15} width="80%" style={{ lineHeight: 'initial' }} />
        </ContentContainer>
      </CardContainer>
    </SkeletonTheme>
  );
}; 
import React, { Component, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import StatsCard from './StatsCard'


// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';


// import { Bar as ChartBar } from 'react-chartjs-2';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

const MetricTitle = styled.p`
  color: ${props => props.theme.dashboard.metric};
  font-size: 0.875rem;
  font-weight: 300;
  text-align: center;
  margin: 0 0.25rem;
`

const Metric = styled.span`
  text-align: center;
  color: ${props => props.theme.dashboard.metric};
  font-size: 0.875rem;
  font-weight: 700;
  margin-left: 0.25rem;
`

const ChartRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.75rem;
`

const BarBackground = styled.div`
  background-color: ${props => props.theme.dashboard.metricTitle || '#ADADAD'};
  height: 1.125rem;
  flex-grow: 1;
  border-radius: 15px;
  overflow: hidden;
`

const BarFill = styled.div`
  background-color: ${props => props.theme.dashboard.chartAccent || '#22C55E'};
  width: ${({ width }) => width};
  height: 100%;
  border-radius: 15px;
  transition: width 0.3s ease-in-out;
`

const ChartLabel = styled.span`
  color: ${props => props.theme.dashboard.metric || '#FFFFFF'};
  font-size: 0.875rem;
  font-weight: 700;
  flex-shrink: 0;
  margin-left: 0.5rem;
`

export const InspectionMetrics = ({ stats, tooltipText }) => {
  const theme = useContext(ThemeContext);
  const renderStats = () => {
    // Return fragment directly
    return (
      <>
        {stats &&
          Object.keys(stats).map(key => (
            // Removed Stat wrapper
            <MetricTitle key={`${key}`}>
              {camelCaseToLabel(key)}:<Metric>{stats[key]}</Metric>
            </MetricTitle>
          ))}
      </>
    )
  }

  const createdCount = stats?.created || 0;
  const completedCount = stats?.completed || 0;

  const percentCompletedValue = createdCount > 0 ? Math.round((completedCount / createdCount) * 100) : 0;
  const percentCompleted = `${percentCompletedValue}%`;

  const camelCaseToLabel = value => {
    let splitted = value.split(/(?=[A-Z])/)
    splitted[0] = splitted[0].charAt(0).toUpperCase() + splitted[0].substring(1)
    return splitted.join(' ')
  }

  return (
    <StatsCard title={'Inspection Metrics'} tooltipText={tooltipText}>
      {/* Added wrapper div with flex styles for stats row */}
      <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', flexWrap: 'wrap' }}>
        {renderStats()}
      </div>
      {/* Container div for the chart */}
      <div> 
        <ChartRow>
          <BarBackground>
            <BarFill width={percentCompleted} />
          </BarBackground>
          <ChartLabel>{percentCompleted}</ChartLabel>
        </ChartRow>
      </div>
    </StatsCard>
  )
}

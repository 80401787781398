import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import StatsCard from './StatsCard'

const Metric = styled.p`
  text-align: center;
  color: ${props => props.theme.dashboard.metric};
  font-size: 2.5rem;
  align-self: center;
`

export const AverageInspectionRating = ({ inspections, tooltipText }) => {
  const [finalAverage, setFinalAverage] = useState(0)
  useEffect(() => {
    if (inspections?.length > 0) {
      let totalScore = 0
      let inspectionsWithScores = 0
      inspections.forEach(inspection => {
        let score = null
        let letsImproveAreas = 0
        let greatAreas = 0
        if (inspection?.areas?.length > 0) {
          inspection?.areas?.forEach(area => {
            if (area.rating === 1) {
              letsImproveAreas++
            } else if (area.rating === 3) {
              greatAreas++
            }
          })
          score = Math.floor(
            (greatAreas / (letsImproveAreas + greatAreas)) * 100
          )
        }
        if (score) {
          totalScore += score
          inspectionsWithScores++
        }
      })
      if (totalScore) {
        setFinalAverage(Math.floor(totalScore / inspectionsWithScores))
      } else {
        setFinalAverage(0)
      }
    } else {
      setFinalAverage(0)
    }
  }, [inspections])


  return (
    <StatsCard title={'Average Inspection Rating'} tooltipText={tooltipText}>
      <Metric>{finalAverage}%</Metric>
    </StatsCard>
  )
}

import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components'
import { UncontrolledPopover, PopoverBody } from 'reactstrap'

const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 0.5rem;
  gap: 0.5rem;
`

const LabelDot = styled.span`
  background-color: ${({color}) => color};
  height: 0.75em;
  width: 0.75em;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.25em;
`

const Label = styled.p`
  color: ${props => props.theme.dashboard.metricTitle || '#ADADAD'};
  font-size: 0.875rem;
  white-space: nowrap;
  margin: 0;
  display: flex;
  align-items: center;
`

const LabelArray = ({ data, shortenTo, colorPalette = [] }) => {
  const theme = useContext(ThemeContext);

  // Define fallback generic colors if no palette provided
  const fallbackColors = [
    theme.dashboard.chartPrimary,
    theme.dashboard.chartSecondary,
    theme.dashboard.chartTertiary,
    theme.dashboard.chartOther,
    theme.dashboard.chartAccent
  ];
  const colorsToUse = colorPalette.length > 0 ? colorPalette : fallbackColors;

  const labelData = shortenTo ? data.slice(0, shortenTo) : data
  const labelArray = labelData?.map((entry, index) => {
    // Use index to get color from palette
    const colorIndex = index % colorsToUse.length;
    const dotColor = colorsToUse[colorIndex];

    return (
      <Label key={`label-${index}`} dark={shortenTo}>
        <LabelDot color={dotColor}/>
        {entry.name}
      </Label>
    )
  })

  if (shortenTo && data.length > shortenTo) {
    const extras = data.length - shortenTo
    labelArray.push(<Label key={`label-${shortenTo}`} dark>+{extras} more</Label>)
  }
  return (
    <>
      {labelArray}
    </>
  )
}

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

export const PieLabelArray = ({ data, shortenTo, elementId, colorPalette }) => {
  return (
    <>
      <LabelRow id={elementId}>
        <LabelArray data={data} shortenTo={shortenTo} colorPalette={colorPalette} />
      </LabelRow>
      {data?.length > shortenTo ? (
        <UncontrolledPopover
          target={elementId}
          trigger="hover"
          placement="top"
          hideArrow
          modifiers={{ flip: { behavior: ['top'] } }}
        >
          <PopoverBody>
            <PopoverContent>
              <LabelArray data={data} colorPalette={colorPalette} />
            </PopoverContent>
          </PopoverBody>
        </UncontrolledPopover>
      ) : null}
    </>
  )
}

import React, { Component, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import StatsCard from './StatsCard'
// import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';


import { Bar as ChartBar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ChartRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const Bar = styled.div`
  background-color: ${props => props.theme.dashboard.chartAccent};
  height: 1rem;
  ${({ width }) => (width ? 'width: ' + width * 70 + '%;' : '')}
  min-width: fit-content;
`

const BarLabel = styled.p`
  color: ${props => props.theme.colors.white};
  font-size: 0.75rem;
  text-align: center;
`

const ChartLabel = styled.span`
  color: ${props => props.theme.dashboard.metricTitle};
  font-size: 0.75rem;
  font-weight: 300;
`

export const ConversionRate = ({ stats, tooltipText }) => {
  const theme = useContext(ThemeContext);
  const percentCompleted = `${Math.round((stats?.won / stats?.created) * 100) ||
    0}%`

  const options = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: theme.dashboard.metricTitle
        }
      },
      title: {
        display: true,
        text: percentCompleted,
        font: {
          size: 25,
        },
        color: theme.dashboard.metric
      },
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        font: {
          size: 15
        }
      },

      y: {
        ticks: {
          color: theme.dashboard.metricTitle
        },
        categoryPercentage: 1.0,
      }
    }
  };

  const labels = ['Proposal Created', 'Proposal Won']
  const dataProposals = [stats.created, stats.won]

  const data = {
    labels,
    datasets: [
      {
        axis: 'y',
        data: dataProposals,
        fill: false,
        backgroundColor: [theme.dashboard.chartPrimary, theme.dashboard.chartAccent],
        barThickness: 25,
        borderWidth: 1,
      },
      // {
      //   label: 'Created',
      //   data: [stats.created],
      //   backgroundColor: '#0088FE',
      //   // barThickness: 25,
      //   // borderWidth: 1
      // },
      // {
      //   label: 'Won',
      //   data: [stats.won],
      //   backgroundColor: '#198754',
      //   // barThickness: 25,
      //   // borderWidth: 1
      // },
    ],
  };

  return (
    <StatsCard title={'Conversion Rate'} tooltipText={tooltipText}>
      <div style={{ width: '100%', flexGrow: 1, maxHeight: '7rem' }}>
        <ChartBar options={options} data={data} />
      </div>
    </StatsCard>
  )
}

import React from 'react'
import styled from 'styled-components'
import { FaInfoCircle } from 'react-icons/fa';


const CardWrapper = styled.div`
  overflow: hidden;
  border-radius: 12px;
  background-color: ${props => props.theme.dashboard.cardBackground};
  ${({containerStyles}) => containerStyles ? containerStyles : ''}
  display: flex;
  flex-direction: column;
`

const StatTitleContainer = styled.div`
  background-color: ${props => props.theme.dashboard.cardHeaderBackground};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 0.5rem 0;
`

const StatTitle = styled.h2`
  color: ${props => props.theme.dashboard.cardTitle};
  font-size: 1rem;
  font-weight: 500;
`

const StatContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding: 1rem;
`

const InfoIcon = styled(FaInfoCircle)`
  cursor: pointer;
  color: ${props => props.theme.dashboard.metricTitle || '#ADADAD'};
  margin-left: 0.5em;
`;

const StatCard = ({ title, children, containerStyles, faqComponent, tooltipText }) => {

  const shouldShowIcon = !!tooltipText;

  return (
    <CardWrapper containerStyles={containerStyles}>
      <StatTitleContainer>
        <StatTitle>
          {title}
          {faqComponent}
        </StatTitle>
        {shouldShowIcon && (
          <InfoIcon 
            size={'1em'}
            title={tooltipText} 
          />
        )}
      </StatTitleContainer>
      <StatContent>{children}</StatContent>
    </CardWrapper>
  )
}

export default StatCard;

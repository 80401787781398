import React, { useContext, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'
import StatsCard from './StatsCard'
import numeral from 'numeral'
import MotivationalMessage from './MotivationalMessage'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';


import { Bar as ChartBar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StatRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const Bar = styled.div`
  background-color: ${props => props.theme.dashboard.chartPrimary};
  height: 1.5rem;
  ${({ width }) => (width ? 'width: ' + width * 75 + '%;' : '')}
  border-radius: 5px;
`

const LabelColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-right: 0.5rem;
`

const EmployeeName = styled.p`
  color: ${props => props.theme.dashboard.metricTitle};
  font-size: 0.75rem;
  text-align: left;
  white-space: nowrap;
`

const EmployeeSales = styled.p`
  color: ${props => props.theme.dashboard.metric};
  font-size: 0.75rem;
  text-align: left;
  font-weight: 700;
`

export const SalesLeaderboard = ({ stats, tooltipText }) => {
  const theme = useContext(ThemeContext);
  const bluePalette = theme.dashboard.chartSalesGradient || [];

  const sortedData = useMemo(() => (
    stats
      ? Object.values(stats).sort((a, b) => b.sales - a.sales).slice(0, 3)
      : []
  ), [stats]);

  const names = sortedData.map(data => data.name);
  const sales = sortedData.map(data => data.sales);

  const backgroundColors = useMemo(() => (
    sortedData.map((_, index) => {
      const colorIndex = index % bluePalette.length;
      return bluePalette[colorIndex];
    })
  ), [sortedData, bluePalette]);

  const options = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: theme.dashboard.metricTitle
        }
      },
      title: {
        display: false,
        color: theme.dashboard.metric
      },
      tooltip: {
        callbacks: {
          title: () => '',
          label: function (context) {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.x);
          }
        }
      }
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawTicks: false,
          drawBorder: false,
          drawOnChartArea: false
        },
      },

      y: {
        ticks: {
          color: theme.dashboard.metricTitle,
          font: {
            size: 12
          }
        },
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false
        },
        categoryPercentage: 1.0,
      }
    }
  };

  const dataChart = {
    labels: names,
    datasets: [{
      axis: 'y',
      data: sales,
      fill: false,
      backgroundColor: backgroundColors,
      barThickness: 25
    }],
  }

  return (
    <StatsCard
      title={'Sales Leaderboard'}
      tooltipText={tooltipText}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      {sortedData.length > 0
        ? <div style={{ width: '100%', flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: '8rem' }}>
            <ChartBar options={options} data={dataChart} />
          </div>
        : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
            <MotivationalMessage message="Let's get some names on this board!" />
          </div>
      }
    </StatsCard>
  )
}
